/* *************************************************************************
>> View.js Component


 g. papaioannou (g_papaioannou@rocketmail.com)
 29-03-2023 - Fixed the user's email in draft submissions
			- Removed the code of the fetch users' email and replaced it with the auth.user.data.email.

13-02-2024 - Disabled Custom Option CSS to the Form builder at line 211
 ************************************************************************* */

import React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectRoot, resetSubmissions, getSubmissions, saveSubmission, Form, selectError, Errors, getSubmission } from 'react-formio';
// import {  failSubmission } from 'react-formio';
import { push } from 'connected-react-router';
import Loading from '../../../containers/Loading';
import translateGR from '../../../translate/translateGR';
import asyncFetch from '../../../helpers/helperFunctions';
import checkRequiredFields from '../../../helpers/checkRequiredFields';
import { AppConfig } from '../../../config';
import exportSubmissionDataPDF from './Submission/Item/exportSubmissionDataPDF';
import { toast } from 'react-toastify';
import { ExportConst, FormConst, NoFurtherSubmissionsForThisForm } from '../../../config';
// import { Prompt } from 'react-router-dom'
import StayOnPage from '../../../helpers/StayOnPage';
import { RouteLeavingGuard } from '../../../helpers/RouteLeavingGuard';
import cleanDataBeforeSubmission from '../../../helpers/cleanDataBeforeSubmission';
import singleSubmissionValidator from '../../../helpers/singleSubmissionValidator';
import datesAreOnSameDay from '../../../helpers/datesAreOnSameDay';
import EndFormDateMessage from '../../../components/EndFormDateMessage';
import updateUserMetaData from './Submission/Item/updateUserMetaData';
import { Redirect } from 'react-router-dom';
import { sendEmailToAdmin, sendEmailToUser } from '../../../helpers/sendEmails';

const notify = (msg, sec) => {
	toast.success(msg, {
		position: 'top-right',
		autoClose: sec,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
	});
};

let stayOnPage = new StayOnPage();

const View = class extends PureComponent {
	state = {
		userEmail: '',
	};

	//   componentDidMount() {
	// 	this.props.getSubmissions(1);
	// 	this.setState({userEmail : JSON.parse(localStorage.getItem('formioUser')).data.email })
	//   }

	/**
	 * get user either from state or local storage
	 * use user to get his submissions for the current form
	 */
	componentDidMount() {
		const { auth, /* userSubmissions, getSubmissions, */ getUserSubmissions, /* resetSubmission, */ form } = this.props;

		if (auth === undefined || auth.authenticated === false) {
			return;
		}

		// resetSubmission('submission');
		this.props.getSubmissions(1);
		//console.log("DID MOUNT userSubmissions", userSubmissions)
		if (auth.user === null) {
			const user = JSON.parse(localStorage.getItem('formioUser'));
			getUserSubmissions(user.owner, form.id);
		} else {
			getUserSubmissions(auth.user._id, form.id);
		}
		this.setState({ userEmail: JSON.parse(localStorage.getItem('formioUser')).data.email });
	}

	render() {
		if (this.props.auth === undefined || this.props.auth.authenticated === false) {
			return <Redirect to="/" />;
		}

		const {
			auth,
			connecteduser,
			submission,
			hideComponents,
			onSubmit,
			errors,
			// options,
			form: { form, isActive: isFormActive, url },
			history,
			submissions: { submissions, isActive: isSubActive },
			/*userSubmissions */
		} = this.props;

		// const userEmail = this.state.userEmail

		//console.log( Date.parse(form.properties.endDate) + ' vs ' + Date.now());

		if (!form.properties) {
			return (
				<>
					<p>Προκλήθηκε σφάλμα, ανανεώστε την σελίδα</p>
				</>
			);
		}

		const endFormDate = new Date(form.properties.endDate);
		const currentDate = new Date();
		const dateDifference = currentDate - endFormDate;
		/* console.log(endFormDate + ' vs ' + currentDate);
		console.log("Difference ", dateDifference); */
		/* USE OF datesAreOnSameDay()
		When defining a form's End Date the time is NOT set to 23:59.
		There is a case when the Form Date is Ended (time included) but the Day stays the same (a user is supposed to submit).
		e.g. the Form ends on 21/02/2021 at 08:30. A user can't submit on 21/02/2021 at 08:31.
		Thus, an additional check is needed to let a user submit in the case above */
		if (dateDifference > 0 && !datesAreOnSameDay(endFormDate, currentDate)) {
			return <EndFormDateMessage formTitle={form.title} />;
		} else {
			// const dateToDisplay = `${endDate.getDate()  }/${  endDate.getMonth()+1  }/${  endDate.getFullYear()}`

			/* if (isActive) {
				return <Loading />;
			} */

			if (isFormActive || isSubActive) {
				return <Loading />;
			}

			const styleNoFurtherSubsMsg = {
				color: '#f86c6b',
			};
			/* console.log("submissions.submissions",submissions.submissions)
			console.log("submissions ",submissions)
			console.log("userSubmissions ",userSubmissions)
			console.log("userEmail ",this.state.userEmail) */
			let singleSubmission = singleSubmissionValidator(form);

			let numberOfSubmissions;
			if (submissions !== undefined) {
				numberOfSubmissions = submissions.reduce((s, n) => {
					return s + (n.data.userEmail === this.state.userEmail && n.metadata.state === 'submitted');
				}, 0);
			} else {
				numberOfSubmissions = 0;
			}
			//console.log("Number of submissions: ", numberOfSubmissions)
			return (
				<>
					{/* <Prompt when={stayOnPage.getStay() === true} message={MessageYouHaventSaved.MESSAGE_YOU_HAVENT_SAVED} /> */}
					{(singleSubmission && numberOfSubmissions < 1) || !singleSubmission ? (
						<RouteLeavingGuard
							// When should shouldBlockNavigation be invoked,
							// simply passing a boolean
							// (same as "when" prop of Prompt of React-Router)
							when={stayOnPage.getStay() === true}
							// Navigate function
							navigate={path => history.push(path)}
							userRole={auth.user.data.selectedAdminRole}
							formID={form._id}
							// Use as "message" prop of Prompt of React-Router
							shouldBlockNavigation={location => {
								// This case it blocks the navigation when:
								if (location.pathname === `/form/${form._id}/edit` || location.pathname === `/form/${form._id}/delete` || location.pathname === `/form/${form._id}/export` /* || location.pathname === `/logout` */) {
									return false;
								} else {
									return true;
								}
							}}
						/>
					) : (
						<div></div>
					)}
					<h4>Υποβολή αίτησης: {form.title}</h4>
					{/* <h4 style={{color: "#20a8d8"}}> Η Φόρμα λήγει στις: {dateToDisplay} - 23:59</h4> */}
					<hr />
					<Errors errors={errors} />
					{(singleSubmission && numberOfSubmissions < 1) || !singleSubmission ? (
						<Form
							form={form}
							submission={submission}
							url={url}
							hideComponents={hideComponents}
							onSubmit={submission => onSubmit(submission, auth.user.data.email, connecteduser)}
							//   onLoad={onLoad}
							// options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
						/>
					) : (
						<h5 style={styleNoFurtherSubsMsg}>{NoFurtherSubmissionsForThisForm.MSG_NO_FURTHER_SUBMISSIONS}</h5>
					)}
					{/* <Form
					form={form}
					submission={submission}
					url={url}
					hideComponents={hideComponents}
					onSubmit={onSubmit}
					//   onLoad={onLoad}
					options={{ ...{ template: 'bootstrap3', iconset: 'fa' }, ...options }}
				/> */}
				</>
			);
		}
	}
};

const validateFormMetaData = async formid => {
	const form = await asyncFetch({
		url: `${AppConfig.apiUrl}/form/${formid}`,
		method: 'GET',
	});
	return form;
	// TODO check dates and throw error if date not in boundaries
};

const setSubmissionStatus = (dispatch, submission, ownProps, form, connecteduser) => {
	const userData = localStorage.getItem('userData');
	let userDataLocal = null;
	if (userData !== 'undefined') {
		/* console.log("userData", userData)
		console.log("user") */
		userDataLocal = JSON.parse(localStorage.getItem('userData'));
	}

	if (submission.data.saveDraft !== undefined) {
		submission.metadata.state = submission.state;
	}
	if (userDataLocal) {
		submission.data = updateUserMetaData(submission.data, userDataLocal);
	}

	//submission.data = cleanDataBeforeSubmission(submission.data)
	submission.data = cleanDataBeforeSubmission(submission.data);

	dispatch(
		saveSubmission('submission', submission, ownProps.match.params.formId, (err, savedSubmission) => {
			checkRequiredFields(form, submission);
			if (!err) {
				// dispatch(resetSubmissions('submission'));
				// dispatch(push(`/form/${ownProps.match.params.formId}/submission/${savedSubmission._id}`));
				// 	exportSubmissionDataPDF(savedSubmission);
				var msg = '---';
				var sec = 6000;

				if (savedSubmission.metadata.state === FormConst.SUBMITTED) {
					//afora palioteres formes poy den eixe dilothei to exprotPDF
					if (form.properties.exportPDF === undefined) {
						form.properties.exportPDF = FormConst.EXPORT_PDF;
					}

					//CHECK EMAILS
					//User Email
					if (form.properties.sendUserEmail === undefined) {
						form.properties.sendUserEmail = FormConst.DO_NOT_NOTIFY_USER_BY_EMAIL;
					} //else  if (form.properties.sendUserEmail === 'YES') {
					else if (form.properties.sendUserEmail === 'YES') {
						//gsis custom email contact
						const searchObject = form.components.find((el) => el.key === "userEmailContact");
						//console.log("searchObject", searchObject)
						if (searchObject) {
							sendEmailToUser(form.title, savedSubmission._id, savedSubmission.data[searchObject.key])
							//gsis custom email contact
						} else {
							sendEmailToUser(form.title, savedSubmission._id, savedSubmission.data.userEmail)
						}
					}

					//Admin Email
					if (form.properties.sendAdminEmail === undefined) {
						form.properties.sendAdminEmail = FormConst.DO_NOT_NOTIFY_ADMIN_BY_EMAIL;
					} else if (form.properties.sendAdminEmail === 'YES') {
						sendEmailToAdmin(form, form.properties.customOwnerEmail, savedSubmission, connecteduser)
					}
					//CHECK EMAILS

					if (form.properties.exportPDF === FormConst.EXPORT_PDF) {
						msg = ExportConst.MSG_SUCCESS_SUBMITTED_WITH_PDF;
						sec = 6000;

						exportSubmissionDataPDF(savedSubmission);
					} else {
						msg = ExportConst.MSG_SUCCESS_SUBMITTED_WITHOUT_PDF;
						sec = 4000;
					}
				} else if (savedSubmission.metadata.state === FormConst.DRAFT) {
					msg = ExportConst.MSG_SUCCESS_DRAFT;
					sec = 4000;
				}
				stayOnPage.setStay(false);
				notify(msg, sec);
				dispatch(resetSubmissions('submission'));
				dispatch(push(`/form/${ownProps.match.params.formId}/submission/${savedSubmission._id}`));
				stayOnPage.setStay(true);
			}
		}),
	);
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		auth: auth,
		form: selectRoot('form', state),
		connecteduser: selectRoot('connecteduser', state),
		submissions: selectRoot('submissions', state),
		userSubmissions: selectRoot('userSubmissions', state),
		errors: [selectError('form', state), selectError('submission', state)],
		options: {
			noAlerts: true,
			language: 'en',
			i18n: JSON.parse(translateGR),
		},
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSubmit: async (submission, email, connecteduser) => {
			try {
				const tempForm = await validateFormMetaData(ownProps.match.params.formId);
				const formEndDate = new Date(tempForm.properties.endDate);
				const currentDate = new Date();
				if (formEndDate < currentDate && !datesAreOnSameDay(formEndDate, currentDate)) {
					throw new Error('Έχει λήξει η περίοδος υποβολής της αίτησης');
				}
				submission.data.userEmail = email;
				setSubmissionStatus(dispatch, submission, ownProps, tempForm, connecteduser);
			} catch (error) {
				alert(error.message);
			}
		},

		getSubmissions: (page, query) => dispatch(getSubmissions('submissions', page, { limit: ExportConst.LIMIT }, ownProps.match.params.formId)),

		getUserSubmissions: (ownerid, formid) => {
			dispatch(
				getSubmissions('submission', 0, { owner: ownerid }, formid, (err, submissionOfUser) => {
					if (submissionOfUser !== undefined) {
						const [subm] = submissionOfUser;
						dispatch(getSubmission('submission', subm._id, subm.form));
					}
				}),
			);
		},
	};
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     onSubmit: (submission) => {
//       dispatch(saveSubmission('submission', submission, ownProps.match.params.formId, (err, submission) => {
//         if (!err) {
//           dispatch(resetSubmissions('submission'));
//           dispatch(push(`/form/${ownProps.match.params.formId}/submission/${submission._id}`))
//         }
//       }));
//     }
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(View);
